import { FC, useEffect, useRef, useState } from 'react';
import {
  DatePicker,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { cancelIconButtonStyles } from '../wbsCreate/documentPane/WbsCreateDocument';
import ProgressBar from '../../shared/progressBar/progressBar';
import XLSX from 'xlsx';
import Axios from 'axios';
import ENV from '../../../env.json';
import { CREATE_INSTRUCTION_EP, CREATE_LOCATION_EP, CREATE_TEAMS_FOLDER_FROM_CREATE_PBS, PBS_SAVE, SAVE_PBS_INSTRUCTION_EP, UPLOAD_PRODUCT_EXCEL } from '../../shared/endpoints';
import { getContractingUnit, getProject } from '../../shared/util';
import { Form } from 'react-final-form';
import { Dropdown, IDropdownOption, mergeStyles, Stack, TextField } from 'office-ui-fabric-react';
import { analyzeCustomDocument } from '../../services/formRecognizerService';
import { useHistory } from 'react-router-dom';
import { Input } from '@fluentui/react-components';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { saveVPStateAttr } from '../../reducers/visualPlanReducer';
import { messageService } from '../../services/messageService';
import { saveFormData } from '../../reducers/projectBreakdownReducer';
import { PENDING_DEVELOPMENT_STATUS_ID } from '../../types/projectMolecule';
import { projectInitialFormData } from '../../types/project';
import { PRODUCT_ITEM_TYPE_REG_CONTRACT_ID } from '../../types/projectBreakdownStructure';
import client from '../../api';
const theme = getTheme();
interface ProjectModalProps {
  isOpen: boolean;
  onCloseModel: any;
}
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    display: 'flex',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '80vw',
    minHeight: '80vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
      preview: {
        width: '50%',
      },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const deleteIcon: IIconProps = { iconName: 'Delete' };

const modalStyles = {
  main: {
    height: '500px', // Customize the height
    width: '600px', // You can also adjust the width
  },
};

const dragAndDropStyles = mergeStyleSets({
  button: {
    height: '29px',
    marginLeft: '2px',
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    background: 'black',
  },
});

type ExcelData = (string | number)[][];  

const ProjectModal: FC<ProjectModalProps> = ({ isOpen, onCloseModel }) => {

  // Define your colors
  const bg = 'white'; // Replace with your actual grey color
  const fill = 'rgb(242, 116, 34)'; // Replace with your actual primary color

    // Define the styles using mergeStyles
    const styles = {
      wrapper: mergeStyles({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft:15,
          marginBottom: 13
          // height: '100vh',
          // backgroundColor: bg,
      }),
      hourglass: mergeStyles({
          display: 'block',
          // background:bg,
          // margin: '3em auto',
          width: '2em', // Increased width for better visibility
          height: '4em', // Increased height for better visibility
          marginRight: 8,
          animation: 'hourglass 1s linear infinite',
          // Adding animation styles here
      }),
      outer: mergeStyles({
          fill: fill,
      }),
      middle: mergeStyles({
          fill: bg,
      }),
  };

  const keyframes = `
  @keyframes hourglass {
      0% {
          transform: rotate(0deg);
          box-shadow: inset ${fill} 0 -0em 0 0, inset ${bg} 0 -2em 0 0, inset ${fill} 0 -4em 0 0;
      }
      80% {
          transform: rotate(0deg);
          box-shadow: inset ${fill} 0 -2em 0 0, inset ${bg} 0 -2em 0 0, inset ${fill} 0 -2em 0 0;
      }
      100% {
          transform: rotate(180deg);
          box-shadow: inset ${fill} 0 -2em 0 0, inset ${bg} 0 -2em 0 0, inset ${fill} 0 -2em 0 0;
      }
  }`;

  const history = useHistory();
  const dispatch = useDispatch();
  const [excelFileName, setExceltFileName] = useState<string | null>('');
  const [pdfFileName, setPdfFileName] = useState<string | null>('');
  const { t } = useTranslation();
  const [isProgress, setIsProgress] = useState(false);
  const [numberOfRecords, setNumberOfRecords] = useState('');
  const [data, setData] = useState<ExcelData>([]);
  const [pdf, setPdf] = useState(null);
  const [toImport, setToImport] = useState<any>('0');
  const [fileUrl, setFileUrl] = useState<string | null>('');
  const [mainTitle, setMainTitle] = useState<any>('');
  const [contractNr, setContractNr] = useState<any>('');
  const [finishedCount, setFinishedCount] = useState(0);
  const [loadCount, setLoadCount] = useState(0);
  const [showHourGlass, setShowHourGlass] = useState(false);
  const [ excelData, setExcelData ] = useState<RowData[]>([]);  

  const excelColumnNames = {
    Hoofdgemeente: 'Hoofdgemeente',
    PC: 'PC',
    Straat: 'Straat',
    Huisnr: 'Huisnr.',
    Busnr: 'Busnr.',
    RefnrE: 'Ref.nr E',
    RefnrG: 'Ref.nr G',
    RefnrKabelTV: 'Ref.nr Kabel TV'
  }

  const options: IDropdownOption[] = [
    { key: '0', text: t('Syndi') },
    { key: '1', text: t('Vicky') },
    { key: '2', text: t('Fluvius')}
  ];
  const [isPdf, setIsPdf] = useState(false);
  const productDetails = [
    {
      id: 'Ordre de travail',
      name: t('ordreDeTravail'),
      value: '',
    },
    {
      id: 'Powalco Id',
      name: t('powalcoId'),
      value: '',
    },
    {
      id: 'Type de flux chantier',
      name: t('typeDeFluxChantier'),
      value: '',
    },
    {
      id: 'autorisation',
      name: t('autorisation'),
      value: '',
    },
    {
      id: 'priorite',
      name: t('Priorite'),
      value: '',
    },
    {
      id: 'Date de transfert',
      name: t('dateDeTransfert'),
      value: '',
    },
    {
      id: 'Type Dossier',
      name: t('typeDossier'),
      value: '',
    },
    {
      id: 'Type Client',
      name: t('typeClient'),
      value: '',
    },
    {
      id: 'Type Batiment',
      name: t('typeBatiment'),
      value: '',
    },
    {
      id: 'Type Reseau',
      name: t('typeReseau'),
      value: '',
    },
    {
      id: 'necessaire',
      name: t('necessaire'),
      value: '',
    },
    {
      id: 'Nom du chantier',
      name: t('nomDuChantier'),
      value: '',
    },
    {
      id: 'Commune',
      name: t('commune'),
      value: '',
    },
    {
      id: 'Localite',
      name: t('localite'),
      value: '',
    },
    {
      id: 'code postal',
      name: t('codePostal'),
      value: '',
    },
    {
      id: 'Remarques sur chantier',
      name: t('remarquesSurChantier'),
      value: '',
    },
    {
      id: 'Demandeur',
      name: t('demandeur'),
      value: '',
    },
    {
      id: 'GSM',
      name: t('gsm'),
      value: '',
    },
    {
      id: 'mail',
      name: t('mail'),
      value: '',
    },
  ];
  const [products, setProducts] = useState<any>({});

  useEffect(() => {
    console.log('resdsf!!!21@!#@#');
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(keyframes));
    document.head.appendChild(style);
    
    return () => {
        document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    setToImport('0');
    setExceltFileName('');
    setPdfFileName('');
    setNumberOfRecords('');
    setFileUrl(null);
    setIsPdf(false);
    setProducts({});
    // modalClose()
  }, []);

  const uploadToAPI = async (file: any) => {
    const formData = new FormData(); // Create a FormData object
    formData.append('file', file);
    const response = await Axios({
      method: 'POST',
      url: `${ENV.BASE_URL}/${UPLOAD_PRODUCT_EXCEL}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
        Cu: getContractingUnit(),
        Project: getProject(),
        'Content-Type': 'multipart/form-data',
        'X-API-Key': 'ae43693a-1006-45c6-b48a-d0747240d2ed',
      },
    });
    setIsProgress(false);
    if (response.status === 200) {
      const { result } = response.data;
      setNumberOfRecords(result.numberOfRecords);
    }
  };

  const handleDropExcel = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    console.log('excel');
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        setExceltFileName(file.name);
        handleExcelFileChange(file, true);
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const handleDropPdf = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === 'application/pdf') {
        setPdfFileName(file.name);
        handlePdfFileChange(file, true);
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const handlePdfFileChange = (file: any, isDropped?: boolean) => {
    setPdfFileName(file.name);
    setIsPdf(true);
    const uploadedFile = file;
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      setPdf(uploadedFile);
      const url = URL.createObjectURL(uploadedFile);
      setFileUrl(url);
    } else {
      alert('Please upload a valid PDF file.');
    }
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        try {
          const response: any = await analyzeCustomDocument(arrayBuffer);
          constructTheField(response.documents[0].fields);
        } catch (error) {
          console.error('Error during document analysis:', error);
        } finally {
        }
      };
    }
  };

  const constructTheField = (data: any) => {
    const keys = Object.keys(data);

    setProducts(data);
  };

  const handleExcelFileChange = (file: any, isDropped?: boolean) => {
    setIsPdf(false);
    setIsProgress(true);
    setExceltFileName(file.name);
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const binaryStr = event.target?.result; // Optional chaining to avoid null reference
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        }) as ExcelData;
        setData(jsonData);
        if(toImport!== '2'){
          await uploadToAPI(file);
        }
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    };
    if (file instanceof Blob) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsBinaryString(file.target.files[0]);
    }

    // for excel iterate & create product
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
          const binaryStr = e.target?.result;
          if (binaryStr) {
              // Read workbook
              const workbook = XLSX.read(binaryStr, { type: 'binary' });

              // Use the first sheet
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];

              // Convert sheet to JSON format with column headers as keys
              const jsonData = XLSX.utils.sheet_to_json<RowData>(sheet, { header: 1 });

              // Extract headers and data separately
              const headers = jsonData[0];
              const rows = jsonData.slice(1);

              // Map rows to objects with headers as keys
              const rowData = rows.map((row) =>
                  headers.reduce((acc : any, header: any, idx: any) => {
                      acc[header as string] = row[idx];
                      return acc;
                  }, {} as RowData)
              );

              setExcelData(rowData);
          }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const modalClose = () => {
    setToImport('0');
    setExceltFileName('');
    setPdfFileName('');
    setNumberOfRecords('');
    setFileUrl(null);
    setIsPdf(false);
    onCloseModel();
    setData([]);
  };

  const onProductChange = (e: any, key: any) => {
    const tempProducts = { ...products };
    tempProducts[key].value = e.target.value;
    setProducts((prev: any) => ({ ...prev, tempProducts }));
  };

  const onCardClick = (type: string) => {
    type === 'pdf' ? setIsPdf(true) : setIsPdf(false);
  };

  const onImportChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: any
  ) => {
    setToImport(option.key);
  };

  const deleteExcel = () => {
    setExceltFileName('');
    setData([]);
    setNumberOfRecords('');
  };

  const deletePdf = () => {
    setPdfFileName('');
    setPdf(null);
  };

  const dateToString = (date: any) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const stringToDate = (dateString: any) => {
    const date = dateString.split(' ');
    date.pop();
    const [day, month, year] = date[0].split('/');
    return new Date(year, month - 1, day);
  };

  const onSubmit = () => {
    if (toImport) {
      if (pdf && data.length) {
        onCloseModel();
        setTimeout(() => {
          dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
          dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
          dispatch(saveVPStateAttr('slectedPBSForBor', null));
        }, 1000);
        localStorage.setItem('product', JSON.stringify(products));
        modalClose();
      } else {
        return alert('Please upload the documents');
      }
    } else {
    }
  };

  const createTeamsFolder = async (folderName: string, ) => {
    const data = JSON.stringify({});
    let config: any = {
      method: 'post',
      maxBodyLength: Infinity,
      url: CREATE_TEAMS_FOLDER_FROM_CREATE_PBS,
      headers: {
        'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
        // 'Project': getProject(),
        'FolderName': folderName,
        'ProductGuid': uuidv4(),
        'InstructionType': 'text',
        'DriveId': '',
        'ParentFolderDriveItemId': '',
        'Content-Type': 'application/json',
      },
      data : data
    };

    try{
      const response = await client.request(config);
      return response?.data?.result;
    } catch(error){
        console.log("createTeamsFolder is failed ",error)
    }    
      return null;
  }

  const createSharableLink = async (projectId: string, driveId: string, driveItemId: string) => {
    try{
      const response = await client.get('Wbs/GetLinkFromTeams',{
        headers:{"X-API-Key": "fcbbdac5-d0ad-45cb-8b45-dd8550785eb6",
                  DriveId: driveId,
                  DriveItemId: driveItemId,
                  Type: "edit"
                 }});
       return response?.data?.result;          
    } catch(error){
      console.error("createSharableLink is failed ", error);
    }

    return null;    
  }

  const createInstrution = async (sharableLink: string) => {
    const payload = {
      headerTitle: null,
      // id: instructionId,
      instructionType: "100",
      instructionsDetails: sharableLink,
      isDeleted: false,
      isSaved: false,
      name: "Fluvius",
      pbsInstructionFamilyId: '626253e2-981c-4dcd-b084-a801adfdab1e',
      pbsInstructionLink: [
          {
            id: null,
            title: "Folder Link",
            link: sharableLink,
            type: "Website",
            value: sharableLink
          }
        ],
        sequenceCode: null,
        title: null
    }
    // https://uprincev5apistagging.azurewebsites.net/api/PbsInstruction/CreateInstructions
    try{
        const response = await client.post(CREATE_INSTRUCTION_EP, payload);
        return response?.data?.result;
    } catch(error){
        console.log("createInstrution is failed ",error);
    }
    return null;
  }

  const linkInstructionToPbs = async () => {
    const payload = {
      id: uuidv4(),


    }

    try{
        const response = await client.post(SAVE_PBS_INSTRUCTION_EP, payload);
        return response?.data?.result;
    } catch(error){
        console.log("linkInstructionToPbs is failed ",error);
    }
    return null;
  }

  const mapLocationPayload = (mapLocation: any) => {
   const mapData =  {
      type: mapLocation?.results[0]?.type,
      id: mapLocation?.results[0]?.id,
      score: 0,
      entityType: null,
      matchConfidence: {
          score: 0
      },
       address : mapLocation?.results[0]?.address,
      position: {
          lat: mapLocation?.results[0]?.position?.lat,
          lon: mapLocation?.results[0]?.position?.lon
      },
      viewport: {
          topLeftPoint: {
              lat: mapLocation?.results[0]?.viewport?.topLeftPoint?.lat,
              lon: mapLocation?.results[0]?.viewport?.topLeftPoint?.lon
          },
          btmRightPoint: {
              lat: mapLocation?.results[0]?.viewport?.btmRightPoint?.lat,
              lon: mapLocation?.results[0]?.viewport?.btmRightPoint?.lon
          }
      },
      boundingBox: {
          topLeftPoint: {
              lat: 0,
              lon: 0
          },
          btmRightPoint: {
              lat: 0,
              lon: 0
          }
      },
      dataSources: {
          geometry: {
              id: null
          }
      }}

      return mapData;
  }

  const getAddressDetails = async (address: string) => {
    try {
      const fetchAddressUrl = 'https://atlas.microsoft.com/search/address/json';
      const response = await fetch(
        `${fetchAddressUrl}?api-version=1.0&subscription-key=${ENV.MAP_KEY}&query=${encodeURIComponent(address)}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      alert("Something went wrong while fetching location details");      
    }
    return null;
  }

  const createPayLoad = (pbsName: string, pbsContract: string, projectId: string, pbsParentId?: string, address?: string, pbsLocation?: string) => {
    const parentPayload = {
      id : uuidv4(),
      name: pbsName,
      pbsLocation: pbsLocation ? pbsLocation : "",
      pbsProductItemTypeId: PRODUCT_ITEM_TYPE_REG_CONTRACT_ID,
      pbsProductStatusId: PENDING_DEVELOPMENT_STATUS_ID,
      pbsToleranceStateId: projectInitialFormData.projectToleranceStateId,
      pbsType: "regular",
      projectId: projectId,
      startDate: new Date(),
      endDate: new Date(),
      contract: pbsContract,
      parentId: pbsParentId && pbsParentId!=="" ? pbsParentId : null,
      // mapLocation: address? {
      //   address:{
      //     freeformAddress: address
      //   }
      // } : ""
    }
    return parentPayload;
  }

  const createProductApi = async (projectId: string, data: any) => {
    try{
      const response = await client.post(PBS_SAVE, data, {headers: {'Content-Type': 'application/json', project: projectId}});
      // const response = await client.post('Pbs/CreateProductSW', data, {headers: {'Content-Type': 'application/json', project: projectId, 'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6'}});
      return response?.data?.result;
    } catch(error){
        console.error("Create Pbs API failed ", error);
    }
    return null;
  }

  const createProductForExcelData = async () => {
      const projectId = getProject();
      if(projectId === null || projectId === undefined || projectId ===""){
        return;
      }

      setIsProgress(true);
      setLoadCount(excelData.length+1);
      setShowHourGlass(true);

      try{
        const parentPayload = createPayLoad(mainTitle, contractNr, projectId);
        const parentData = await createProductApi(projectId,parentPayload);
        // const folderData = await createTeamsFolder(mainTitle);
        // console.log("folderData ",folderData);
        if(parentData?.productId){
          // const linkDetails = await createSharableLink(projectId, folderData?.folderDetails?.driveId, folderData?.folderDetails?.id);
          // console.log("linkDetails ",linkDetails?.link?.webUrl );
          //const instructionDetails = await createInstrution(linkDetails?.link?.webUrl);
          for(let index=0; excelData.length>index; index++){
            let title = "";
            let address = "";
            let isPcAvailable = false;
            setFinishedCount(index+1);
            const rowdata = excelData[index];
            const electricity = rowdata[excelColumnNames.RefnrE];
            const gas = rowdata[excelColumnNames.RefnrG];

            // if both electricity & gas is available then create 3 product
            if(electricity && electricity!=="" && gas && gas!==""){
              title = contractNr;

              if(rowdata[excelColumnNames.PC] && rowdata[excelColumnNames.PC]!==""){
                title = title + " - " + rowdata[excelColumnNames.PC];
                isPcAvailable = true;
              }

              if(rowdata[excelColumnNames.Hoofdgemeente] && rowdata[excelColumnNames.Hoofdgemeente]!==""){
                title = title + " - " + rowdata[excelColumnNames.Hoofdgemeente];
                address = rowdata[excelColumnNames.Hoofdgemeente] + ", ";
              }

              if(isPcAvailable){
                address = address + rowdata[excelColumnNames.PC] + " ";
              }

              if(rowdata[excelColumnNames.Straat] && rowdata[excelColumnNames.Straat]!==""){
                title = title + " - " + rowdata[excelColumnNames.Straat];
                address = address + rowdata[excelColumnNames.Straat] + " ";
              }

              if(rowdata[excelColumnNames.Huisnr] && rowdata[excelColumnNames.Huisnr]!==""){
                title = title + " " + rowdata[excelColumnNames.Huisnr];
                address = address + rowdata[excelColumnNames.Huisnr];
              }

              if(rowdata[excelColumnNames.Busnr] && rowdata[excelColumnNames.Busnr]!==""){
                title = title + " - " + rowdata[excelColumnNames.Busnr];
              }

              const locationDetails =  await getAddressDetails(address);
              const addressPayload = mapLocationPayload(locationDetails);
              const addressDetails = await client.post(CREATE_LOCATION_EP, addressPayload);

              if(addressDetails?.data?.result){ // make sure the address is available
                const innerParentPayload = createPayLoad(title, contractNr, projectId, parentData.productId, address, addressDetails?.data?.result);
                const innerParentData = await createProductApi(projectId,innerParentPayload);

                if(innerParentData?.productId){
                  const childPbsElecticTitle  = title + " - " + "ELEK - " + rowdata[excelColumnNames.RefnrE];
                  const childPbsElecticPayload = createPayLoad(childPbsElecticTitle, contractNr, projectId, innerParentData.productId, address, addressDetails?.data?.result);
                  await createProductApi(projectId,childPbsElecticPayload);

                  const childPbsGasTitle  = title + " - " + "GAS - " + rowdata[excelColumnNames.RefnrG];
                  const childPbsGasPayload = createPayLoad(childPbsGasTitle, contractNr, projectId, innerParentData.productId, address, addressDetails?.data?.result);
                  await createProductApi(projectId,childPbsGasPayload);
                }
              }

            } else if(electricity && electricity!==""){ //if electricity data only available
                title = contractNr;

                if(rowdata[excelColumnNames.PC] && rowdata[excelColumnNames.PC]!==""){
                  title = title + " - " + rowdata[excelColumnNames.PC];
                  isPcAvailable = true;
                }

                if(rowdata[excelColumnNames.Hoofdgemeente] && rowdata[excelColumnNames.Hoofdgemeente]!==""){
                  title = title + " - " + rowdata[excelColumnNames.Hoofdgemeente];
                  address = rowdata[excelColumnNames.Hoofdgemeente] + ", ";
                }

                if(isPcAvailable){
                  address = address + rowdata[excelColumnNames.PC] + " ";
                }

                if(rowdata[excelColumnNames.Straat] && rowdata[excelColumnNames.Straat]!==""){
                  title = title + " - " + rowdata[excelColumnNames.Straat];
                  address = address + rowdata[excelColumnNames.Straat] + " ";
                }

                if(rowdata[excelColumnNames.Huisnr] && rowdata[excelColumnNames.Huisnr]!==""){
                  title = title + " " + rowdata[excelColumnNames.Huisnr];
                  address = address + rowdata[excelColumnNames.Huisnr];
                }

                if(rowdata[excelColumnNames.Busnr] && rowdata[excelColumnNames.Busnr]!==""){
                  title = title + " - " + rowdata[excelColumnNames.Busnr];
                }

                title = title + " - " + "ELEK - " +rowdata[excelColumnNames.RefnrE];

                const locationDetails =  await getAddressDetails(address);
                const addressPayload = mapLocationPayload(locationDetails);
                const addressDetails = await client.post(CREATE_LOCATION_EP, addressPayload);

                if(addressDetails?.data?.result){ // make sure the address is available
                  const childPbsElecticPayload = createPayLoad(title, contractNr, projectId, parentData.productId, address, addressDetails?.data?.result);
                  await createProductApi(projectId,childPbsElecticPayload);
                }

            } else if(gas && gas!==""){ //if gas data only available
                title = contractNr;

                if(rowdata[excelColumnNames.PC] && rowdata[excelColumnNames.PC]!==""){
                  title = title + " - " + rowdata[excelColumnNames.PC];
                  isPcAvailable = true;
                }

                if(rowdata[excelColumnNames.Hoofdgemeente] && rowdata[excelColumnNames.Hoofdgemeente]!==""){
                  title = title + " - " + rowdata[excelColumnNames.Hoofdgemeente];
                  address = rowdata[excelColumnNames.Hoofdgemeente] + ", ";
                }

                if(isPcAvailable){
                  address = address + rowdata[excelColumnNames.PC] + " ";
                }

                if(rowdata[excelColumnNames.Straat] && rowdata[excelColumnNames.Straat]!==""){
                  title = title + " - " + rowdata[excelColumnNames.Straat];
                  address = address + rowdata[excelColumnNames.Straat] + " ";
                }

                if(rowdata[excelColumnNames.Huisnr] && rowdata[excelColumnNames.Huisnr]!==""){
                  title = title + " " + rowdata[excelColumnNames.Huisnr];
                  address = address + rowdata[excelColumnNames.Huisnr];
                }

                if(rowdata[excelColumnNames.Busnr] && rowdata[excelColumnNames.Busnr]!==""){
                  title = title + " - " + rowdata[excelColumnNames.Busnr];
                }

                title  = title + " - " + "GAS - " +rowdata[excelColumnNames.RefnrG];

                const locationDetails =  await getAddressDetails(address);
                const addressPayload = mapLocationPayload(locationDetails);
                const addressDetails = await client.post(CREATE_LOCATION_EP, addressPayload);

                if(addressDetails?.data?.result){ // make sure the address is available
                  const childPbsGasPayload = createPayLoad(title, contractNr, projectId, parentData.productId, address, addressDetails?.data?.result);
                  await createProductApi(projectId,childPbsGasPayload);
                }

            }
          }
        }        

      } catch(error){
          console.error("Can't create product by using excel ",error);
      }
      
      setIsProgress(false);
      setShowHourGlass(false);
      setLoadCount(0);
      setFinishedCount(0);
      onCloseModel();
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        render={({ form }) => {
          return (
            <form>
              <Modal
                titleAriaId={'Add Product'}
                isOpen={isOpen}
                scrollableContentClassName="body"
                isBlocking={true}
                dragOptions={undefined}
                // styles={modalStyles}
              >
                <div className={contentStyles.header}>
                  <span>{t('addProject')}</span>
                  <IconButton
                    styles={cancelIconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => {
                      onCloseModel();
                      modalClose();
                    }}
                  />
                </div>
                <ProgressBar show={isProgress} />
                <div className={contentStyles.body} style={{ height: '50vh' }}>
                  <div style={{ flexBasis: '70%' }} className="preview">
                    {isPdf ? (
                      <>
                        {pdf && (
                          <div>
                            <h2>Preview:</h2>
                            {fileUrl && (
                              <div style={{ marginTop: '20px' }}>
                                <iframe
                                  src={fileUrl}
                                  width={`100%`}
                                  height={'600px'}
                                  title="PDF Preview"
                                  style={{
                                    border: '1px solid #ccc',
                                    backgroundColor: 'white',
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : data.length ? (
                      <div>
                        {data.length > 0 && (
                          <table
                            style={{
                              borderCollapse: 'collapse',
                              width: '100%',
                            }}
                          >
                            <thead>
                              <tr>
                                {data[0].map((header: any, index: any) => (
                                  <th
                                    key={index}
                                    style={{
                                      border: '1px solid #000',
                                      padding: '8px',
                                    }}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {row.map((cell: any, cellIndex: any) => (
                                    <td
                                      style={{
                                        border: '1px solid #000',
                                        padding: '8px',
                                      }}
                                      key={cellIndex}
                                    >
                                      {cell}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexBasis: '30%',
                      borderLeft: '1px solid #ddd',
                      padding: '0px 0px 0px 20px',
                      overflow: 'auto',
                    }}
                    className="upload"
                  >
                    <div style={{ width: '100%', height: '90%' }}>
                      <Stack>
                        <Stack.Item grow={6}>
                          <div className={`${classNames.fullWidth}`}>
                            <Dropdown
                              label={t('import')}
                              options={options}
                              onChange={onImportChange}
                              selectedKey={toImport}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                      <Label>Attachment</Label>
                      <div className={`${classNames.fullWidth}`}>
                        <div>
                          {!excelFileName ? (
                            <>
                              <div
                                onDragOver={handleDragOver}
                                onDrop={handleDropExcel}
                                style={{
                                  border: '2px dashed #ccc',
                                  padding: '40px',
                                  textAlign: 'center',
                                  marginBottom: '20px',
                                }}
                              >
                                <p>Drag and drop your Excel file here</p>
                              </div>
                              <button
                                className="uploadButton"
                                onClick={() => {
                                  document.getElementById('fileInput')?.click();
                                }}
                              >
                                Upload File
                              </button>
                              <input
                                type="file"
                                id="fileInput"
                                accept=".xlsx, .xls"
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (file) {
                                    handleExcelFileChange(file);
                                  }
                                }}
                                style={{
                                  display: 'none',
                                }}
                              />
                            </>
                          ) : (
                            <div className="afterUploaded">
                              <div
                                className="card"
                                onClick={() => onCardClick('excel')}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <h3>Uploaded File</h3>
                                  {numberOfRecords && (
                                    <h3>
                                      Number Of Records : {numberOfRecords}
                                    </h3>
                                  )}
                                </div>

                                <div>
                                  <div className="uploadedExcel">
                                    <div
                                      style={{
                                        wordBreak: 'break-all',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {excelFileName}
                                    </div>
                                    <IconButton
                                      className="excelPlaceHolder"
                                      iconProps={deleteIcon}
                                      onClick={deleteExcel}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {+toImport && toImport!=='2'? (
                        <div>
                          <Label>Attachment</Label>
                          <div className={`${classNames.fullWidth}`}>
                            <div>
                              {!pdfFileName ? (
                                <>
                                  <div
                                    onDragOver={handleDragOver}
                                    onDrop={handleDropPdf}
                                    style={{
                                      border: '2px dashed #ccc',
                                      padding: '40px',
                                      textAlign: 'center',
                                      marginBottom: '20px',
                                    }}
                                  >
                                    <p>Drag and drop your PDF file here</p>
                                  </div>
                                  <button
                                    className="uploadButton"
                                    onClick={() => {
                                      document
                                        .getElementById('filePdfInput')
                                        ?.click();
                                    }}
                                  >
                                    Upload File
                                  </button>
                                  <input
                                    type="file"
                                    id="filePdfInput"
                                    accept=".pdf"
                                    onChange={(e) => {
                                      const file = e.target.files?.[0];
                                      if (file) {
                                        handlePdfFileChange(file);
                                      }
                                    }}
                                    style={{
                                      display: 'none',
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <div className="afterUploaded">
                                    <div
                                      className="card"
                                      onClick={() => onCardClick('pdf')}
                                    >
                                      <h3>Uploaded File</h3>
                                      <div>
                                        <div className="uploadedExcel">
                                          <div
                                            style={{
                                              wordBreak: 'break-all',
                                              textAlign: 'center',
                                            }}
                                          >
                                            {pdfFileName}
                                          </div>
                                          <IconButton
                                            className="excelPlaceHolder"
                                            iconProps={deleteIcon}
                                            onClick={deletePdf}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sideScroll">
                                    {Object.keys(products).length ? (
                                      Object.keys(products).map((key) => (
                                        <div style={{ padding: '10px 0px' }}>
                                          <div>{key}</div>
                                          {key === 'Date de transfert' ? (
                                            <DatePicker
                                              placeholder={t('selectadate')}
                                              ariaLabel={t(
                                                'projectCompletionDate'
                                              )}
                                              value={stringToDate(
                                                products[key].value
                                              )}
                                              formatDate={dateToString}
                                            />
                                          ) : (
                                            <Input
                                              key={key}
                                              className={`${classNames.fullWidth}`}
                                              style={{
                                                border: '1px solid #000',
                                                color: '#000',
                                                padding: '4px 8px',
                                                marginTop: '10px',
                                              }}
                                              value={products[key].value}
                                              onChange={(e) =>
                                                onProductChange(e, key)
                                              }
                                            />
                                          )}
                                        </div>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                    {/* <table style={{ marginTop: '20px', width: '100%', textAlign: 'left', border:'1' }}>
                        <thead>
                            <tr>
                                {excelData.length > 0 && Object.keys(data[0]).map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {excelData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {Object.values(row).map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table> */}

                      {toImport === '2' && 
                      <div>
                        <TextField
                          label={t('Main Title')}
                          value={mainTitle}
                          onChange={(event, value) => {
                            setMainTitle(value);
                          }}
                        />
                      </div>}
                      
                      {toImport === '2' && 
                      <div>
                        <TextField
                          label={t('Contract nr')}
                          value={contractNr}
                          onChange={(event, value) => {
                            setContractNr(value);
                          }}
                        />
                      </div>}

                    </div>
                    {Object.keys(products).length ? (
                      <div className="save_btn">
                        <PrimaryButton
                          iconProps={addIconWhite}
                          text={t('Import')}
                          type="submit"
                          onClick={onSubmit}
                          style={{
                            marginTop: 20,
                            marginBottom: 30,
                            marginRight: 20,
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    <div style={{display:'flex', flexDirection:'row', alignSelf:'end', gap:20}}>
                      {showHourGlass && toImport === '2' &&
                      <div className={styles.wrapper}>
                        <svg
                            className={styles.hourglass}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 120 206"
                            preserveAspectRatio="none"
                        >
                            <path
                                className={styles.middle}
                                d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                            />
                            <path
                                className={styles.outer}
                                d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                            />
                         </svg>
                        {finishedCount}/{loadCount}
                      </div>}

                        {toImport === '2' &&
                          <div className="save_btn">
                            <PrimaryButton
                              iconProps={addIconWhite}
                              text={t('Import')}
                              type="submit"
                              disabled={mainTitle === "" || contractNr === "" }
                              onClick={()=>{                                
                                createProductForExcelData();
                              }}
                              style={{
                                marginTop: 20,
                                marginBottom: 30,
                                marginRight: 20,
                              }}
                            />
                          </div>
                        }
                    </div>
                    
                  </div>
                </div>
                <div></div>
              </Modal>
            </form>
          );
        }}
      ></Form>
    </div>
  );
};

export default ProjectModal;

interface RowData {
  [key: string]: any; // Allows access by column name
}