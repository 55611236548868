import React, { useEffect, useState } from 'react';
import {AcceptType} from "../../../types/myDayPlanning";
import EventBody from "../../projectDayPlanning/listPane/eventBody";
import * as TdDropBox from "../../../shared/dnd/dropBox";
import {ICommandBarItemProps, mergeStyleSets, Modal} from "@fluentui/react";
import {PMOL_ACTIVITY_TYPES} from "../../../types/projectMolecule";
import {useDispatch, useSelector} from "react-redux";
import {clonePmolDayPlanning, saveMyDayPlanningStateAttr} from "../../../reducers/myDayPlanningReducer";
import {saveStateAttr} from "../../../reducers/uprinceReducer";
import {updatePlanBoardsPmolStatus} from "../../../reducers/projectDayPlanningReducer";
import { messageService } from '../../../services/messageService';
import { getContractingUnit, isTeamPlanboardMyEnv } from '../../../shared/util';
import Axios from 'axios';
import ENV from '../../../../env.json'
import { DELETE_PMOL } from '../../../shared/endpoints';
import i18n from '../../../../i18n';
import ProgressBar from '../../../shared/progressBar/progressBar';
import CopyPmolModal from './copyPmolModal/CopyPmolModal';
import { useTranslation } from 'react-i18next';
import client from '../../../api';

const classNames = mergeStyleSets({
	modalContainer: {
		maxWidth: '550px',
		width: '100%',
	}
})

function CustomEvent({event}: any) {
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const [showProgress, setShowProgress] = useState<boolean>(false);
	const [showCopyPmolModal, setShowCopyPmolModal] = useState<boolean>(false);
	const [isTeams, setIsTeams] = useState(false)

	const selectedDate = useSelector((state: any) => state?.mydPlan?.selectedDate);

	const openPmol = (pmol: any) => {
		dispatch(saveMyDayPlanningStateAttr('selectedMyDpPmol', pmol));
		dispatch(saveStateAttr('contractingUinit', pmol?.contractingUinit));
		dispatch(saveStateAttr('projectSequenceCode', pmol?.projectSequenceCode));
	};

	const createPmol = (pmol: any, type: string) => {
		if (pmol?.projectMoleculeId) {
			let data = {
				...pmol,
				pmolId: pmol?.projectMoleculeId,
				typeId: type
			};

			Promise.all([
				dispatch(clonePmolDayPlanning(data))
			]).then(() => {
				//TODO:  reloadListPaneItem();
			});
		}
	};

	const copyPmol = (pmol: any) => {
		dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {
			event: pmol,
			isCopyModalOpen: true
		}));
	};

	const deletePmol = async (pmol: any) => {
		setShowProgress(true)
		const payload = {
			id: pmol?.id
		}
		await Axios({
			method: 'POST',
			url: `${ENV.BASE_URL}/${DELETE_PMOL}`,
			headers: {
				"Authorization": `Bearer ${localStorage?.getItem('accessToken')}`,
				"Cu": pmol?.contractingUinit,
				"Project": pmol?.projectSequenceCode
			},
			data: payload
		}).then((res) => {
			setShowProgress(false);
			messageService.sendMessage({ reloadList: true });
		}).catch((err) => {
			alert("Something went wrong when deleting this PMOL")
		}).finally(() => {
			setShowProgress(false)
		})
	}

	const handlePmolStatusChange = (data: any) => {
		Promise.all([
			dispatch(updatePlanBoardsPmolStatus(data))
		]).then(() => {
			if(isTeamPlanboardMyEnv()){
				messageService.sendMessage({ reloadList: true });
			}
		});
	};

	const _overflowItems: ICommandBarItemProps[] = [
		{
			key: 'open', 
			text: i18n.t('open'), 
			onClick: () => openPmol(event), 
			iconProps: {iconName: 'MoveToFolder'}
		},
		{
			key: 'create',
			text: i18n.t('createTransportPMOL'),
			onClick: () => createPmol(event, PMOL_ACTIVITY_TYPES.TRAVEL),
			iconProps: {iconName: 'Add'}
		},
		{
			key: 'copyPmolToTeams', 
			text: i18n.t('copyPmolToTeams'), 
			// onClick: () => copyPmol(event),
			onClick: () => {
				setShowCopyPmolModal(true);
				setIsTeams(true)
			},
			iconProps: {iconName: 'Copy'}
		},
		{
			key: 'copyPmolToDates', 
			text: i18n.t('copyPmolToDates'),
			// onClick: () => copyPmol(event),
			onClick: () => {
				setShowCopyPmolModal(true);
				setIsTeams(false)
			},
			iconProps: {iconName: 'Copy'}
		},
		{
			key: 'delete', 
			text: i18n.t('delete'), 
			onClick: () => deletePmol(event), 
			iconProps: {iconName: 'Delete'}
		},
	];

	return (
		<>
			<TdDropBox.DropBox
				item={event}
				day={selectedDate}
				type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL]}
				isPmol={true}
			>
				<ProgressBar show={showProgress}/>
				<EventBody 
					pmol={event} 
					overflowItems={_overflowItems} 
					isDp={true}
					handlePmolStatusChange={(data: any) => handlePmolStatusChange(data)}
				/>
			</TdDropBox.DropBox>

			<Modal containerClassName={classNames.modalContainer} isOpen={showCopyPmolModal} onDismiss={() => setShowCopyPmolModal(false)}>
				<CopyPmolModal 
					headerLabel={isTeams ? t('copyPmolToTeams') : t('copyPmolToDates')}
					onCloseModal={() => setShowCopyPmolModal(false)}
					event={event}
					type={isTeams ? true : false}
				/>
			</Modal>
		</>
)}

export default CustomEvent;