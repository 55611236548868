import { Calendar, Checkbox, ContextualMenu, DayOfWeek, DefaultButton, defaultCalendarStrings, FirstWeekOfYear, FontWeights, getTheme, IBasePickerSuggestionsProps, IconButton, IDragOptions, IIconProps, ITag, Label, mergeStyleSets, Modal, PrimaryButton, Stack, TagPicker } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { uPrinceTheme } from '../../../../../theme';
import { Field, Form } from 'react-final-form';
import { formatDateTimeToISOString } from '../../../../shared/util';
import client from '../../../../api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import ValidationLabel from '../../../../shared/ValidationLabel/component';
import {useId} from '@fluentui/react-hooks';
import { useSelector } from 'react-redux';
import TaxonomyGrid from '../../../../shared/taxonomyField/taxonomyGrid';
import { ORGANIZATION_TAXONOMY_LEVEL_TYPES } from '../../../../types/cuMyCalendar';
import '../../../style/style.css'
import { CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_EP } from '../../../../shared/endpoints';

const theme = getTheme();
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const peopleIcon: IIconProps = { iconName: 'People' };

const contentStyles = mergeStyleSets({
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  footer: {
    flex: '1 1 auto',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 24px 14px 24px',
    textAlign: 'end',
  },
})

const classNames = mergeStyleSets({
  container: {
    padding: 8,
    // width: '100%'
  },
  dates: {
    padding: '0 12px'
  },
  label: {
    marginTop: '2%'
  },
  selectTeamsContent: {
    border: '1px solid black', 
    borderRadius: '2px', 
    overflowX:'hidden', 
    overflowY:'auto', 
    width: '100%'
  },
  stackSelectedTeams: {
    display:'flex', 
    flexWrap:'wrap', 
    minHeight:'30px'
  },
  stackItems: {
    background:'rgb(243, 242, 241)', 
    margin:'2px'
  },
  span: {
    marginLeft:'8px'
  },
  mainFooter: { 
    display: 'flex', 
    justifyContent: 'flex-end', 
    marginTop: 16, 
    marginBottom: 8 
  }
})

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const CopyPmolModal = (props: {
  headerLabel: string;
  onCloseModal: () => void
  event: any;
  type: boolean
}) => {
  // constants
  const {t} = useTranslation();
  const pickerId = useId('inline-picker');
  const titleId = useId('title');

  // states
  const [restrictedDates, setRestrictedDates] = useState<Date[]>([]);
  const [selectedItems, setSelectedItems] = useState<ITag[]>([]);
  const [isaddTeamOrPersonModalOpen, setAddTeamOrPersonModalOpen] = useState<boolean>(false);
  
  // Taxanomy selection
  const [selectedTeamsList, setSelectedTeamsList] = useState<any[]>([]);
  const [selectNodeIds, setSelectNodeIds] = useState<any[]>([]);

  // redux-helper
  const taxonomyList = useSelector((state: any) => state.os.taxonomyList);

  const getInitialFormValues = () => {
    return {
      // projectName: {value: null, label: null},
    }
  }

  useEffect(() => {
    if (props.type) {
      const currentDate: Date = new Date()
      setRestrictedDates([currentDate])
      const tagList: ITag[] = [currentDate]?.map(item => ({
        key: moment(item).format('DD/MM/YYYY'),
        name: moment(item).format('DD/MM/YYYY'),
        date: item
      }));
      setSelectedItems(tagList)
    }
  }, [props.type])
  
  const onSelectDate = (date: Date): void => {
    if (!props.type) {
      restrictedDates.push(date);
      const tagList: ITag[] = restrictedDates.map(item => ({
          key: moment(item).format('DD/MM/YYYY'),
          name: moment(item).format('DD/MM/YYYY'),
          date: item
      }));
      setRestrictedDates(restrictedDates);
      setSelectedItems(tagList);
    } 
    // else {
    //   setRestrictedDates([])
    //   restrictedDates.push(date);
    //   const tagList: ITag[] = restrictedDates?.map(item => ({
    //     key: moment(item).format('DD/MM/YYYY'),
    //     name: moment(item).format('DD/MM/YYYY'),
    //     date: item
    //   }));
    //   setSelectedItems(tagList)
    // }
  }

  const getTextFromItem = (item: ITag) => item.name;

  const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
    return [];
  };
  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested tags',
    noResultsFoundText: 'No color tags found',
  };

  const onChangeTags = (items: any) => {
    const dates = items.map((item: any) => item.date);

    setSelectedItems(items);
    setRestrictedDates(dates);
  }

  const removeTeams = (id: string) =>{
    const updatedList = selectedTeamsList?.filter((item) => item.id !== id);
    setSelectedTeamsList(updatedList);
  }

  const removeInvalidString = (title: string) =>{
    if(title?.includes(" (Person)")){
      return title?.replace(" (Person)", "");
    }
    else if(title?.includes(" (CU)")){
      return title?.replace(" (CU)", "");
    }
    else if(title?.includes(" (BU)")){
      return title?.replace(" (BU)", "");
    }
    else if(title?.includes(" (Team)")){
      return title?.replace(" (Team)", "");
    }
    else if(title?.includes(" (Department)")){
      return title?.replace(" (Department)", "");
    }
    else{
      return title;
    }
  }

  const renderButtons = (rowInfo: any) => {
    const isParent = (rowInfo.node.id);
    const isPerson = rowInfo?.node?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.PERSON;
    const isTeam = rowInfo?.node?.organizationTaxonomyLevelId == ORGANIZATION_TAXONOMY_LEVEL_TYPES.TEAM;

    if (isPerson || isTeam) {
      return ([
        <div
          key={rowInfo.node.id}
          className={'Indeterminate'}
          style={{ paddingTop: 6 }}
        >
          <span style={{ display: 'none' }}>
            {JSON.stringify(isParent)}
          </span> {/* if u remove this part parent selection not working properly   */}
          <Checkbox
            className='btn btn-outline-success'
            styles={{ root: { verticalAlign: 'middle' } }}
            disabled={isPerson ? true : false}
            checked={selectNodeIds?.includes(rowInfo.node.id)}
            onChange={(_ev, checked) => {
              if (isTeam) {
                const checkedTeamId = rowInfo?.node?.id
                const checkedTeamTitle = rowInfo?.node?.title
                if (checked) {
                  const transforForUi = {
                    id: checkedTeamId,
                    title: checkedTeamTitle
                  }
                  setSelectNodeIds((prev) => [...prev, checkedTeamId])
                  setSelectedTeamsList((prev) => [...prev, transforForUi]);
                } else {
                  setSelectNodeIds((prev) => prev.filter(id => id !== checkedTeamId));
                  setSelectedTeamsList((prev) => prev.filter(team => team.id !== checkedTeamId));
                }
              }
            }}
          />
        </div>,
      ]);
    }
  };

  const handleClose = () => {
    setRestrictedDates([]);
    setSelectedItems([]);
    setSelectNodeIds([])
    props.onCloseModal()
  }

  const onRenderFooter = () => {
    return (
      <div className={classNames.mainFooter}>
        <div className={'footer-btn'}>
          <DefaultButton onClick={handleClose}>
            {t('cancel')}
          </DefaultButton>
          <PrimaryButton type="submit" form={"copy-pmol-form"} disabled={props.type ? selectedTeamsList?.length == 0 : restrictedDates?.length == 0}>
            {t('copy')}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const onSubmit = async (values: any) => {
    const formattedDates = restrictedDates?.map((date: any) => {
      return formatDateTimeToISOString(date)
    });

    const dateArray: string[] = formattedDates?.filter((date: string | null): date is string => date !== null).map((date: string | null) => date as string);
    const teams = selectedTeamsList?.map((team) => {
      return {
        parentId: team.id,
        personId: null,
        isTemporaryTeam: false
      }
    })
    if (values) {
      const payload = {
        pmolId: props.event?.projectMoleculeId,
        contractingUinit: props.event?.contractingUinit,
        projectSequenceCode: props.event?.projectSequenceCode,
        typeId: props.event?.typeId,
        executionDate: dateArray,
        teamList: props.type ? teams : [{
          parentId: props.event?.teamId,
          personId: null,
          isTemporaryTeam: false
        }],
        isTeams: props.type
      };

      await client.post(CREATE_PMOL_CLONE_FOR_MULTIPLE_DAYS_EP, payload, {
        headers: {
          Cu: props.event?.contractingUinit,
          Project: props.event?.projectSequenceCode,
        }
      }).then((res) => {
        if (res?.data?.result) {
          handleClose();
        }
      }).catch((err) => {
        alert(err);
      })
    }
  };

  return (
    <div>
      <div className={contentStyles.header}>
        <span>{props.headerLabel}</span>
        <IconButton
          styles={cancelIconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel='Close popup modal'
          onClick={handleClose}
        />
      </div>

      <div className={classNames.container}>
        <Form
          onSubmit={onSubmit}
          initialValues={getInitialFormValues()}
          validate={values => {
            const errors: any = {};
            if (_.isEmpty(restrictedDates)) {
              errors.selectedDates = t('cloneDatesErrMsg');
            }
            return errors;
          }}
          render={({
            handleSubmit,
            form,
            values
          }) => {
            return (
              <form onSubmit={handleSubmit} style={{width: '100%'}} id="copy-pmol-form">
                <div>
                  {/* Calender */}
                  <Field name="calendar">
                    {({input, meta}) => (
                      <div style={{width: '100%', margin: '0 auto'}}>
                        <Calendar
                          highlightSelectedMonth
                          showGoToToday={false}
                          restrictedDates={restrictedDates}
                          onSelectDate={onSelectDate}
                          strings={defaultCalendarStrings}
                          firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                          firstDayOfWeek={DayOfWeek.Monday}
                          className='copyPmolCalendar'
                        />
                      </div>
                    )}
                  </Field>

                  {/* Date Selection */}
                  <Field name="selectedDates">
                    {({input, meta}) => (
                      <div className={classNames.dates}>
                        <Label htmlFor={pickerId} required={true}>{t('selectedDates')}</Label>
                        <TagPicker
                          removeButtonAriaLabel="Remove"
                          selectionAriaLabel="Selected colors"
                          onResolveSuggestions={filterSuggestedTags}
                          getTextFromItem={getTextFromItem}
                          pickerSuggestionsProps={pickerSuggestionsProps}
                          disabled={props?.type === true ? true : false}
                          selectedItems={selectedItems ?? []}
                          onChange={onChangeTags}
                          inputProps={{
                            id: pickerId,
                            readOnly: true,
                          }}
                          styles={{
                            input: {
                              display:  'none',
                            },
                            // itemsWrapper:{
                            //   maxWidth: '24vw',
                            // }
                          }}
                        />
                        {((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched &&
                          <ValidationLabel validationMessage={meta.error}/>
                        }
                      </div>
                    )}
                  </Field>

                  {/* Select teams */}
                  <Field name="selectedTeams">
                    {({input, meta}) => (
                      <div className={classNames.dates}>
                        <Label className={classNames.label}>{t('selectedTeams')}</Label>
                        <div style={{display: 'flex', gap: 4}}>
                          <div className={classNames.selectTeamsContent}>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} className={classNames.stackSelectedTeams}>
                              {selectedTeamsList?.map((team, index) => (
                                <Stack.Item key={index} className={classNames.stackItems}>
                                  <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                    <span className={classNames.span}>{removeInvalidString(team.title)}</span>
                                    <IconButton
                                      iconProps={{ iconName: 'Cancel' }}
                                      title="Remove"
                                      ariaLabel="Remove"
                                      style={{ paddingLeft:'8px' }}
                                      styles={{
                                        icon: { 
                                          fontSize: '12px' 
                                        }
                                      }}
                                      onClick={() => removeTeams(team.id)}
                                    />
                                  </Stack>
                                </Stack.Item>
                              ))}
                            </Stack>
                          </div>
                          <PrimaryButton 
                            iconProps={peopleIcon}
                            onClick={() => setAddTeamOrPersonModalOpen(true)}
                            style={{ width: '200px' }}
                            disabled={props.type === true ? false : true}
                          >
                            {t('selectTeams')}
                          </PrimaryButton>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                {onRenderFooter()}
              </form>
            )
          }}
        />
      </div>

      <Modal
        titleAriaId={titleId}
        isOpen={isaddTeamOrPersonModalOpen}
        onDismiss={() => 
          setAddTeamOrPersonModalOpen(false)
        }
        isBlocking={true}
        containerClassName={contentStyles.container}
        dragOptions={dragOptions}
      >
          <div className={contentStyles.header}>
            <span id={titleId}>{t('selectForeman')}</span>
            <IconButton
              styles={cancelIconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel='Close popup modal'
              onClick={() => setAddTeamOrPersonModalOpen(false)}
            />
          </div>
          <div style={{ padding: 10, height: 700 }}>
            <TaxonomyGrid
              treeData={taxonomyList ?? []}
              renderButtons={renderButtons}
            />
          </div>
          <div className={contentStyles.footer}>
            <PrimaryButton
              iconProps={cancelIcon}
              text={t('close')}
              style={{ marginTop: 10, marginBottom: 5 }}
              onClick={() => setAddTeamOrPersonModalOpen(false)}
              disabled={selectedTeamsList.length === 0}
            />
          </div>
        </Modal>
    </div>
  )
}

export default CopyPmolModal